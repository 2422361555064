import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  constructor(private socket: Socket) {}

  /**
   * Init all rooms to listen
   * @param roomsName ex: ['PT', '62013013_001']
   */
  initRooms(roomsName: string[]) {
    this.socket.on('connect', () => {
      console.log('socket.io::connect');

      // Connected, let's sign-up for to receive messages for this room
      roomsName.forEach((roomName) => {
        console.log('listening in room ', roomName);
        this.socket.emit('room', roomName)
      });
    });
  }

  /**
   *
   * @param eventName ex: notification_center_new_event
   */
  getMessageFrom(eventName: string): Observable<any> {
    return this.socket.fromEvent(eventName);
  }
}
