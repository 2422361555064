export const environment = {
    production: false,
    baseUrl: 'https://recette.royan.mcd-sirh.fr',
    service: {
        webSocket: {
            url: 'https://recette.royan.mcd-sirh.fr',
            path: '/mcd-service-admin/v1/socket.io',
        },
        categoryEmploye: {
            uri: '/mcd-service-admin/v1/categoryEmploye'
        },
        contractTypes: {
            uri: '/mcd-service-admin/v1/contract-types'
        },
        companies: {
            uri: '/mcd-service-admin/v1/companies'
        },
        restaurants: {
            uri: '/mcd-service-admin/v1/restaurants'
        },
        users: {
            uri: '/mcd-service-admin/v1/users'
        },
        roles: {
            uri: '/mcd-service-admin/v1/roles'
        },
        employes: {
            uri: '/mcd-service-admin/v1/employes'
        },
        stats: {
            uri: '/mcd-service-admin/v1/stats'
        },
        files: {
            uri: '/mcd-service-admin/v1/files'
        },
        docs: {
            uri: '/mcd-service-admin/v1/docs'
        },
        alerts: {
            uri: '/mcd-service-admin/v1/alerts'
        }
    }
};
